import {Pipe, PipeTransform} from '@angular/core';
import {ContentDefinitionKey, ContentDefinitions} from '../../definitions/definitions';
import {ContentDefinition} from '../../definitions/definitions-base';

@Pipe({name: 'contentDefinition'})
export class ContentDefinitionPipe implements PipeTransform {
    transform(definitionKey: ContentDefinitionKey, property?: keyof ContentDefinition, fnArg?: number | number[]) {
        const def = ContentDefinitions[definitionKey];
        if (property === 'getPath') return Array.isArray(fnArg) ? def.getPath(...fnArg) : def.getPath(fnArg);
        return property ? def[property] : def.name.capitalized;
    }
}
